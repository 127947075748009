const config = require('@/../config')
export default {
    data(){return {
        interval:null,
    }},
    methods:{
        async request(){
            try {
                let res = await fetch('/api/1w')
                if (!res.ok) throw new Error('fetch is not ok' + JSON.stringify(res));
                else {
                    let json = await res.json() //{"HALL":"8.63","ROOM":"7.31","STREET":"1.31"}
                    Object.keys(json).forEach(key=>{
                        //console.log(key)
                        document.querySelectorAll(`.CLASS-${key}`).forEach(node=>{
                            node.innerHTML = json[key]
                        })

                    })

                }
            }catch (e) {
                console.error(e)
            }
        }
    },
    mounted() {
        //console.log('mounted', config)
        this.request()
        clearInterval(this.interval)
        this.interval = setInterval(this.request, config.ONE_WIRE_DELAY)
        console.log('mounted', this)
    },
    unmounted() {
        //console.log('unmounted')
        clearInterval(this.interval)
    }
}